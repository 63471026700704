import useAuthStore from '~/store/auth'

export default defineNuxtRouteMiddleware(() => {
  const { isSuperAdmin, isCEO } = storeToRefs(useAuthStore())

  if (!isSuperAdmin.value && !isCEO.value) {
    return navigateTo({
      name: 'dashboard',
    })
  }
})
